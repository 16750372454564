<template>
  <div id="app">
    <Nav />
    <Landing />
    <Footer />
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import Landing from './components/Landing.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Nav,
    Landing,
    Footer,
  }
}
</script>

<style src="./assets/css/tailwind.css"></style>
