<template>
  <div
    class="
      bg-light-purple
      flex flex-col
      sm:flex-row
      justify-between
      lg:py-32
      py-20
      xl:px-36
      lg:px-24
      px-14
    "
  >
    <div class="lg:w-4/12 sm:w-3/12">
      <img
        src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633515199/myrtle/myrtle-logotype_ihouwm.png"
        alt=""
        class="h-24"
      />
      <div class="lg:mt-20 mt-10 text-xs text-center sm:text-left">
        <p>&copy; 2021 Myrtle & Pine Ltd.</p>
        <p class="mt-2">
          <a href="#">Facebook</a> / <a href="#">Twitter</a> /
          <a href="#">LinkedIn</a> / <a href="#">Instagram</a>
        </p>
      </div>
    </div>
    <div
      class="
        xl:w-4/12
        lg:w-6/12
        sm:w-7/12
        flex
        sm:justify-between
        mt-14
        sm:mt-0
      "
    >
      <div class="flex flex-col w-5/12 sm:w-auto">
        <p class="font-semibold text-lg text-light-black">Company</p>
        <a href="#" class="mt-6 text-sm text-darker-black">About</a>
        <a href="#" class="mt-4 text-sm text-darker-black">Expertise</a>
        <a href="#" class="mt-4 text-sm text-darker-black">Services</a>

        <a href="#" class="mt-4 text-sm text-darker-black">The Team</a>
      </div>
      <div class="w-5/12 sm:w-auto">
        <p class="font-semibold text-lg text-light-black">Contact</p>
        <div class="mt-6">
          <p class="text-gray-500">Inquiries:</p>
          <a href="#" class="mt-1 text-sm text-black"
            >myrtleandpine@<br class="sm:hidden" />gmail.com</a
          >
          <br />
          <a href="#" class="mt-1 text-sm text-black"
            >contact@<br class="sm:hidden" />myrtleandpine.com.ng</a
          >
        </div>
        <div class="mt-6">
          <p class="text-gray-500">Office:</p>
          <p class="text-sm">4 Campbell Street, Lagos Island, Lagos.</p>
        </div>
        <div class="mt-6">
          <p class="text-gray-500">Phone:</p>
          <p class="text-sm">+234 803 400 9223</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
