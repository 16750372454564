<template>
  <div
    class="
      lg:px-10
      xl:px-16
      sm:px-10
      px-4
      py-3
      lg:py-2
      lg:flex
      items-center
      justify-between
      relative
    "
  >
    <div class="lg:4/12 flex justify-between items-center">
      <a href="#">
        <img
          src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633515199/myrtle/myrtle-logotype_ihouwm.png"
          alt="logo"
          class="w-6/12 lg:w-4/5 sm:w-auto"
        />
      </a>
      <button
        class="lg:hidden focus:outline-none flex justify-center"
        @click="toggle"
      >
        <img src="../assets/img/close.svg" v-if="isopen" alt="close" />
        <img src="../assets/img/menu.svg" v-else alt="menu" />
      </button>
    </div>
    <div
      class="
        ibm
        text-light-black
        bg-white
        lg:w-7/12
        flex
        justify-between
        lg:text-sm
        sm:-mb-14
        -mb-48
        lg:-mb-0
        absolute
        w-full
        left-0
        bottom-0
        py-5
        lg:py-0
        px-10
        lg:px-0
        lg:relative
        flex-col
        sm:flex-row
        space-y-2
        sm:space-y-0
        z-50
      "
      :class="{ hidden: !isopen, flex: isopen, 'lg:flex': !isopen }"
    >
      <router-link
        to="/"
        class="
          hover:border-b-2
          font-semibold
          border-dark-red
          w-min
          whitespace-nowrap
          px-1
          lg:h-6
          h-6
          sm:w-auto
        "
        >Home</router-link
      >
      <router-link
        to="/about"
        class="
          hover:border-b-2
          font-semibold
          border-dark-red
          w-min
          whitespace-nowrap
          px-1
          lg:h-6
          h-6
          sm:w-auto
        "
        >About Us</router-link
      >
      <router-link
        to="/services"
        class="
          hover:border-b-2
          font-semibold
          border-dark-red
          w-min
          whitespace-nowrap
          px-1
          lg:h-6
          h-6
          sm:w-auto
        "
        >Services</router-link
      >
      <router-link
        to="/team"
        class="
          hover:border-b-2
          font-semibold
          border-dark-red
          w-min
          whitespace-nowrap
          px-1
          lg:h-6
          h-6
          sm:w-auto
        "
        >The Team</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isopen: false,
    };
  },
  methods: {
    toggle() {
      this.isopen = !this.isopen;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");

.ibm {
  font-family: "IBM Plex Sans", sans-serif;
}

.router-link-exact-active {
  border-bottom-width: 2px;
  border-color: #e21637;
}
</style>
