<template>
  <div>
    <div class="relative">
      <!-- Slideshow container -->
      <div class="h-screen sm:h-auto">
        <div class="mySlides relative fade">
          <img
            src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1617832461/stephens-leadership/slide1_cgicqh.png"
            alt="slide1"
            class="object-cover h-screen sm:h-90-screen w-full"
          />
          <div
            class="
              leading-tight
              xl:py-14
              px-8
              py-10
              xl:text-4xl
              lg:text-3xl
              sm:text-2xl
              xl:w-4/12
              lg:w-5/12
              sm:w-6/12
              w-10/12
              absolute
              bg-black bg-opacity-20
              xl:bottom-32
              sm:bottom-16
              bottom-32
              xl:left-28
              lg:left-14
              left-7
            "
          >
            <p class="sm:text-sm text-xs fira text-white opacity-70">
              OUR HUMAN CAPITAL
            </p>
            <h1 class="text-white mt-4 libre font-bold">
              Effective, productive, creative <br />
            </h1>
            <p class="sm:text-base text-sm mt-4 text-white">
              Myrtle & Pine has a dynamic, innovative and dependable team. This
              team is made up of qualified, and seasoned professionals with
              foresight and imagination.
            </p>
            <a
              href="#"
              class="mt-7 underline sm:text-base text-sm text-dark-red"
            >
              Learn More ></a
            >
          </div>
        </div>

        <div class="mySlides relative fade">
          <img
            src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1617832459/stephens-leadership/slide2_khdo6l.png"
            alt="slide2"
            class="object-cover h-screen sm:h-90-screen w-full"
          />
          <div
            class="
              leading-tight
              xl:py-14
              px-8
              py-10
              xl:text-4xl
              lg:text-3xl
              sm:text-2xl
              xl:w-4/12
              lg:w-5/12
              sm:w-6/12
              w-10/12
              absolute
              bg-black bg-opacity-20
              xl:bottom-32
              sm:bottom-16
              bottom-32
              xl:left-28
              lg:left-14
              left-7
            "
          >
            <p class="sm:text-sm text-xs fira text-white opacity-70">
              ABOUT MYRTLE & PINE LIMITED
            </p>
            <h1 class="text-white mt-4 libre font-bold">
              We Bridge the Gap between Business and Clientele
            </h1>
            <p class="sm:text-base text-sm mt-4 text-white">
              Established in 2006 to offer an array of distinctive services in
              sales and installation of satellite TV, security equipment, Pabx
              and video systems, including sales and installation of electrical
              appliances in homes, offices, hotels etc.
            </p>
            <a
              href="#"
              class="mt-7 underline sm:text-base text-sm text-dark-red"
            >
              Learn More ></a
            >
          </div>
        </div>

        <div class="mySlides relative fade">
          <img
            src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1617832458/stephens-leadership/slide3_abmlea.png"
            alt="slide3"
            class="object-cover h-screen sm:h-90-screen w-full"
          />
          <div
            class="
              leading-tight
              xl:py-14
              px-8
              py-10
              xl:text-4xl
              lg:text-3xl
              sm:text-2xl
              xl:w-4/12
              lg:w-5/12
              sm:w-6/12
              w-10/12
              absolute
              bg-black bg-opacity-20
              xl:bottom-32
              sm:bottom-16
              bottom-32
              xl:left-28
              lg:left-14
              left-7
            "
          >
            <p class="sm:text-sm text-xs fira text-white opacity-70">
              OUR VISION
            </p>
            <h1 class="text-white mt-4 libre font-bold">
              We Offer <br />
              Quality and Superior Craftsmanship
            </h1>
            <p class="sm:text-base text-sm mt-4 text-white">
              Our mission is “To offer our clients first-class services with
              superior craftsmanship, at a competitive price.”
            </p>
            <a
              href="#"
              class="mt-7 underline sm:text-base text-sm text-dark-red"
            >
              Learn More ></a
            >
          </div>
        </div>
      </div>

      <!-- Dots/bullets/indicators -->
      <div
        class="dot-container absolute xl:bottom-8 bottom-3 right-1/2 -mr-160"
      >
        <span class="dot" @click="currentSlide(1)"></span>
        <span class="dot" @click="currentSlide(2)"></span>
        <span class="dot" @click="currentSlide(3)"></span>
      </div>
    </div>

    <!-- landing body -->
    <div class="">
      <div class="bg-blue-bg sm:pb-32 pb-16">
        <div
          class="
            flex
            sm:flex-row
            flex-col
            justify-between
            sm:py-32
            py-16
            xl:px-32
            lg:px-24
            px-14
          "
        >
          <div class="sm:w-5/12">
            <p
              class="
                sm:text-sm
                text-xs
                fira
                text-white
                opacity-80
                text-center
                sm:text-left
              "
            >
              OUR SOLUTIONS
            </p>
            <h2
              class="
                mt-4
                libre
                xl:text-4xl
                text-white
                lg:text-3xl
                text-2xl text-center
                sm:text-left
              "
            >
              Improving Business <br />
              Performance through <br />
              Technology.
            </h2>
            <div class="flex justify-center sm:justify-start">
              <!-- <a href=""> -->
              <button
                class="
                  mt-6
                  libre
                  px-10
                  py-3
                  rounded-full
                  bg-white
                  text-dark-blue
                "
              >
                Learn More
              </button>
              <!-- </a> -->
            </div>
          </div>
          <div
            class="sm:w-6/12 text-white mt-8 xl:text-lg lg:text-base text-sm"
          >
            <p>
              Established in 2006 to offer an array of distinctive services in
              sales and installation of satellite TV, security equipment, Pabx
              and video systems, including sales and installation of electrical
              appliances in homes, offices, hotels etc. We are also involve in
              Professional & Technical Training and development as well as Oil &
              Gas Service, with General Logistic Support.
            </p>
          </div>
        </div>

        <div class="xl:pl-32 sm:pl-24 pl-14 flex mx-auto overflow-x-auto">
          <div class="sm:w-80 w-64 flex-grow-0 flex-shrink-0 sm:mr-16 mr-7">
            <img
              src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633519803/myrtle/oil-gas_jfr93o.png"
              alt=""
            />
            <div class="bg-white px-8 py-6">
              <h3 class="font-semibold libre">
                Sales & Installation of Satellite TV, Security Equipment, PABX &
                Video Bells Systems
              </h3>
              <p class="text-xs mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <a href="#" class="inline-block mt-5 text-dark-blue"
                >READ MORE &#8594;</a
              >
            </div>
          </div>

          <div class="sm:w-80 w-64 flex-grow-0 flex-shrink-0 sm:mr-16 mr-7">
            <img
              src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633518176/myrtle/oil-gas_vnlxmw.png"
              alt=""
            />
            <div class="bg-white px-8 py-6">
              <h3 class="font-semibold libre">Oil & Gas Services</h3>
              <p class="text-xs mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <a href="#" class="inline-block mt-5 text-dark-blue"
                >READ MORE &#8594;</a
              >
            </div>
          </div>

          <div class="sm:w-80 w-64 flex-grow-0 flex-shrink-0 sm:mr-16 mr-7">
            <img
              src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633518176/myrtle/training_hsywok.png"
              alt=""
            />
            <div class="bg-white px-8 py-6">
              <h3 class="font-semibold libre">
                Professional & Technical Training & Development
              </h3>
              <p class="text-xs mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <a href="#" class="inline-block mt-5 text-dark-blue"
                >READ MORE &#8594;</a
              >
            </div>
          </div>

          <div class="sm:w-80 w-64 flex-grow-0 flex-shrink-0 sm:mr-16 mr-7">
            <img
              src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633518176/myrtle/electrical_zhiul5.png"
              alt=""
            />
            <div class="bg-white px-8 py-6">
              <h3 class="font-semibold libre">
                Sales & Installation of Electrical Appliances
              </h3>
              <p class="text-xs mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <a href="#" class="inline-block mt-5 text-dark-blue"
                >READ MORE &#8594;</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="py-24 lg:px-32 px-14">
        <h1 class="sm:text-4xl text-2xl text-center libre">
          Our world-class edge begins and
          <br />
          ends with our unique values
        </h1>
        <div class="flex justify-center mt-6">
          <router-link to="services">
            <button
              class="libre px-10 py-3 rounded-full bg-dark-blue text-white"
            >
              Learn More
            </button>
          </router-link>
        </div>

        <div class="mt-24 flex justify-center flex-wrap">
          <div
            class="
              bg-light-purple
              xl:w-2/12
              lg:w-3/12
              sm:w-4/12
              w-full
              px-5
              py-8
              sm:mr-7
            "
          >
            <img src="../assets/img/respect.svg" alt="" class="mx-auto" />
            <h2 class="font-semibold text-center libre mt-3">Respect</h2>
            <!-- <p class="text-xs mt-3">
              Quisque velit nisi, pretium ut lacinia in, elementum id enim.
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </p> -->
          </div>

          <div
            class="
              bg-light-purple
              xl:w-2/12
              lg:w-3/12
              sm:w-4/12
              w-full
              px-5
              py-8
              sm:mr-7
              mt-6
              sm:mt-0
            "
          >
            <img src="../assets/img/innovation.svg" alt="" class="mx-auto" />
            <h2 class="font-semibold text-center libre mt-3">Innovation</h2>
            <!-- <p class="text-xs mt-3">
              Quisque velit nisi, pretium ut lacinia in, elementum id enim.
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </p> -->
          </div>

          <div
            class="
              bg-light-purple
              xl:w-2/12
              lg:w-3/12
              sm:w-4/12
              w-full
              px-5
              py-8
              lg:mt-0
              mt-6
              sm:mr-7
            "
          >
            <img src="../assets/img/integrity.svg" alt="" class="mx-auto" />
            <h2 class="font-semibold text-center libre mt-3">Integrity</h2>
            <!-- <p class="text-xs mt-3">
              Quisque velit nisi, pretium ut lacinia in, elementum id enim.
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </p> -->
          </div>

          <div
            class="
              bg-light-purple
              xl:w-2/12
              lg:w-3/12
              sm:w-4/12
              w-full
              px-5
              py-8
              xl:mt-0
              mt-6
              sm:mr-7
            "
          >
            <img src="../assets/img/excellence.svg" alt="" class="mx-auto" />
            <h2 class="font-semibold text-center libre mt-3">Excellence</h2>
            <!-- <p class="text-xs mt-3">
              Quisque velit nisi, pretium ut lacinia in, elementum id enim.
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </p> -->
          </div>

          <div
            class="
              bg-light-purple
              xl:w-2/12
              lg:w-3/12
              sm:w-4/12
              w-full
              px-5
              py-8
              xl:mt-0
              mt-6
            "
          >
            <img src="../assets/img/simplicity.svg" alt="" class="mx-auto" />
            <h2 class="font-semibold text-center libre mt-6">Simplicity</h2>
            <!-- <p class="text-xs mt-3">
              Quisque velit nisi, pretium ut lacinia in, elementum id enim.
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            </p> -->
          </div>
        </div>
      </div>

      <div class="bg-blue-bg">
        <div
          class="
            flex
            sm:flex-row
            flex-col
            justify-between
            xl:items-start
            items-center
            sm:py-32
            py-16
            xl:px-32
            lg:px-24
            px-14
          "
        >
          <div class="lg:w-5/12 sm:w-6/12">
            <p
              class="
                sm:text-sm
                text-xs
                fira
                text-white
                opacity-80
                text-center
                sm:text-left
              "
            >
              OUR EXPERIENCE
            </p>
            <h2
              class="
                mt-4
                libre
                text-white
                xl:text-4xl
                lg:text-3xl
                text-2xl text-center
                sm:text-left
              "
            >
              We’re a World-class team of Multidisciplinary Experts
            </h2>
            <div class="text-white mt-8 xl:text-lg lg:text-base text-sm">
              <p>
                Micheal Miebi is the managing Director of Myrtle & Pine with a
                B.Sc in Business Administration. He began his career in 1990
                with Lever Brothers Nigeria limited as a product line operator.
                He joined Wilsat Communications as a marketing supervisor in
                1993 where he coordinated campaigns to boost sales of CATV/TVRO
                systems as well as supply of telecommunication PBX systems.
                <br />
                He proceeded to join Nigerian Security Printing & Marketing Plc
                as the Team Head of the Security Finishing Department. With
                adequate experience acquired, Michael established Myrtle & Pine
                to provide installation of electrical appliances with
                state-of-the-art equipments and professional expertise
              </p>
            </div>
            <div class="flex justify-center sm:justify-start">
              <router-link to="/about">
                <button
                  class="
                    mt-6
                    libre
                    px-10
                    py-3
                    rounded-full
                    bg-white
                    text-dark-blue
                  "
                >
                  Our Experience
                </button>
              </router-link>
            </div>
          </div>
          <div class="w-6/12 sm:block hidden">
            <img
              src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633518420/myrtle/pexels-photo-5647559_cagodv.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="relative">
        <img
          src="https://res.cloudinary.com/dq6iuhnpr/image/upload/q_auto:low/v1633519683/myrtle/Group_114-min_jqhfl9.png"
          alt=""
          class="h-screen sm:h-auto object-cover"
        />
        <div
          class="
            lg:w-5/12
            sm:w-6/12
            px-14
            absolute
            xl:top-44
            lg:top-24
            sm:top-14
            top-36
            lg:left-16
            sm:left-5
          "
        >
          <h2 class="mt-4 libre xl:text-4xl lg:text-3xl text-2xl text-white">
            Gain a Competitive Advantage & Achieve Significant Growth
          </h2>
          <div class="text-white mt-8 lg:text-base text-sm">
            <p>
              We offer principles and methods that we have practiced with
              results. Therefore, our solutions are not merely theoretical, they
              are practical.
            </p>
          </div>
          <router-link to="/services">
            <button
              class="mt-8 libre px-10 py-3 rounded-full bg-dark-blue text-white"
            >
              How We Work
            </button>
          </router-link>
        </div>
      </div>

      <div class="bg-black py-14 xl:px-32 lg:px-20 px-8">
        <h2
          class="
            mt-4
            text-center
            libre
            lg:text-4xl
            sm:text-3xl
            text-2xl text-white
          "
        >
          We bring deep expertise, creative insight <br />
          and extensive knowledge and industry <br />
          experience.
          <br />
          <br />
          Let’s work together.
        </h2>

        <form
          action=""
          class="mt-24 lg:px-28 sm:px-20 px-5 flex flex-wrap justify-between"
        >
          <input
            type="text"
            placeholder="First name*"
            class="
              focus:outline-none
              border-b border-white
              sm:w-5/12
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
          <input
            type="text"
            placeholder="Last name*"
            class="
              mt-14
              sm:mt-0
              focus:outline-none
              border-b border-white
              sm:w-5/12
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
          <input
            type="text"
            placeholder="Email address*"
            class="
              mt-14
              focus:outline-none
              border-b border-white
              sm:w-5/12
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
          <input
            type="number"
            placeholder="Phone"
            class="
              mt-14
              focus:outline-none
              border-b border-white
              sm:w-5/12
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
          <input
            type="text"
            placeholder="What do you want to enquire about?"
            class="
              mt-14
              focus:outline-none
              border-b border-white
              sm:w-5/12
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
          <input
            type="text"
            placeholder="Name of organisation"
            class="
              mt-14
              focus:outline-none
              border-b border-white
              sm:w-5/12
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
          <textarea
            type="text"
            placeholder="Your message*"
            class="
              mt-24
              focus:outline-none
              border-b border-white
              w-full
              bg-transparent
              text-white
              p-2
              placeholder-opacity-60
              lg:text-lg
              text-sm
            "
          />
        </form>
        <div class="flex justify-center my-16">
          <button class="libre px-10 py-3 rounded-full bg-dark-blue text-white">
            Talk to Us
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Nav from "./Nav";
export default {
  components: {
    // Nav,
  },
  data() {
    return {
      slideIndex: 0,
      showtimer: null,
    };
  },
  methods: {
    currentSlide(n) {
      var i;
      var timer;
      var dots = document.getElementsByClassName("dot");
      var slides = document.getElementsByClassName("mySlides");
      clearTimeout(timer);
      if (n > slides.length) {
        n = 1;
      } else if (n < 1) {
        n = slides.length;
      }
      this.slideIndex = n;
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[n - 1].style.display = "block";
      dots[n - 1].className += " active";

      timer = setTimeout(this.showSlides, 4000);
    },
    showSlides() {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      this.slideIndex++;
      if (this.slideIndex > slides.length) {
        this.slideIndex = 1;
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
      this.showtimer = setTimeout(this.showSlides, 4000);
    },
  },
  mounted: function () {
    this.showSlides(this.slideIndex);
  },
  created() {
    document.title = "Myrtle & Pine Limited :: Home";
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500&display=swap");

.libre {
  font-family: "Libre Franklin", sans-serif;
}

.fira {
  font-family: "Fira Sans", sans-serif;
}

.mySlides {
  display: none;
  text-align: left;
}

.dot {
  cursor: pointer;
  height: 3px;
  width: 90px;
  margin: 0 9px;
  background-color: white;
  opacity: 0.5;
  border-radius: 10px;
  display: inline-block;
}

.active,
.dot:hover {
  background-color: white;
  opacity: 1;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
</style>
